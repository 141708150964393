export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAEtqWzfQtZfAjtk329hz7tlEapQS3Ai5Q",
    authDomain: "faxguardian-81803.firebaseapp.com",
    projectId: "faxguardian-81803",
    storageBucket: "faxguardian-81803.appspot.com",
    messagingSenderId: "516772166095",
    appId: "1:516772166095:web:58639d9dd750e910113aba",
    measurementId: "G-V77PH4MFMF"
  },
};
