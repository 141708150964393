import { Observable } from "rxjs";
import { User } from "../_model/user";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { CollectionName } from "../utils/enums/collection-name";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UserService {
  /**
   * It get user by email
   * @param email
   */
  get(uid: string): Observable<User> {
    return this._angularFirestore
      .collection(CollectionName.USER)
      .doc(uid)
      .get()
      .pipe(
        map((userSnap) => {
          if (userSnap.exists) {
            return {
              ...(userSnap.data() as User),
              $key: userSnap.id,
            } as User;
          } else {
            return null;
          }
        })
      );
  }
  /**
  * It get user by email
  * @param email
  */
  get$(uid: string): Observable<User> {
    return this._angularFirestore
      .collection(CollectionName.USER)
      .doc(uid)
      .snapshotChanges()
      .pipe(
        map((userSnap) => {
          if (userSnap.payload.exists) {
            return {
              ...(userSnap.payload.data() as User),
              $key: userSnap.payload.id,
            } as User;
          } else {
            return null;
          }
        })
      );
  }

  /**
   * It set user to database
   * @param email
   */
  set(uid: string, data: any): Promise<void> {
    return this._angularFirestore
      .collection(CollectionName.USER)
      .doc(uid)
      .set(data, {
        merge: true,
      });
  }
  constructor(private _angularFirestore: AngularFirestore) { }
}
