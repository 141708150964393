import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from './user.service';
import { Injectable } from "@angular/core";
import { User } from "../_model/user";
import { CreditData } from '../_model/credit.model';

@Injectable({
  providedIn: "root",
})
export class StateManagementService {
  public user: User = {};
  public creditData: CreditData = {} as any;
  /**
   * It set logged in admin and company info
   * @param data
   */
  setLoggedInfo(data) {
    this.user = data;
  }

  constructor(private _userService: UserService, private _angularFireAuth: AngularFireAuth) {
    this._angularFireAuth.authState.subscribe(async (user) => {
      if (user) {
        this._userService
          .get$(user.uid)
          .subscribe(user => {
            this.user = user;
          });
      } else {
        user = null;
      }
    });
  }
}
