import { AngularFirestore } from '@angular/fire/firestore';
import { first } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { User } from "../_model/user";
import { LoaderService } from "../_service/loader.service";
import { StateManagementService } from "../_service/state-management.service";
import { UserService } from "../_service/user.service";

@Injectable({
  providedIn: "root",
})
export class UserGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable((observer) => {
      this._loaderService.start();
      this._auth.authState.subscribe(async (user) => {
        if (user) {
          let users = await this._userService
            .get(user.uid)
            .pipe(first())
            .toPromise();
          if (users) {
            await this.setDataToState(users);
            observer.next(true);
            observer.complete();
            this._loaderService.stop();
          } else {
            this._auth.signOut();
            this._loaderService.stop();
            window.location.href = "/auth";
            window.location.reload();
            observer.next(false);
            observer.complete();
          }
        } else {
          this._auth.signOut();
          window.location.href = "/auth";
          this._loaderService.stop();
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  /**
   * It set data of logged in user
   */
  async setDataToState(user: User) {
    try {
      let utilsData: any = await (await this._angularFirestore.collection('utils').doc('credits').get().toPromise()).data();
      this._stateManagementService.creditData = utilsData;
      this._stateManagementService.setLoggedInfo(user);
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  constructor(
    private _router: Router,
    private _auth: AngularFireAuth,
    private _userService: UserService,
    private _stateManagementService: StateManagementService,
    private _loaderService: LoaderService,
    private _angularFireFunctions: AngularFireFunctions,
    private _angularFirestore: AngularFirestore
  ) { }
}
